import { DataResult, process, State } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords,
  GridToolbar,
  GridExpandChangeEvent
} from '@progress/kendo-react-grid';
import { Col, Row } from 'react-bootstrap';
import { DefaultGridSettings } from '../../../../constants/grid-settings';
import { toastStore } from '../../../../stores/toast-store';
import { PrivatePage } from '../../../../components/private-page';
import Toolbar from '../../../game-designer/toolbar';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../../components/grid/grid-tool-bar';
import { useCallback, useEffect, useState } from 'react';
import { NoRecords } from '../../../../components/grid/no-records';
import { appStore } from '../../../../stores/app-store';
import {
  AdminAddDisplayLanguageContentAsync,
  AdminDisplayLanguageDocumentResponse,
  AdminDisplayLanguageResponse,
  AdminUpdateDisplayLanguageAsync,
  AdminUpdateDisplayLanguageDocumentAsync,
  DisplayLanguage,
  DisplayLanguageList,
  DisplayLanguageResource,
  GetAdminDisplayLanguageAsync,
  GetAdminDisplayLanguageLatestContentAsync,
  GetAdminDisplayLanguageLatestDocumentAsync,
  GetAllAdminDisplayLanguageDefaultValuesAsync
} from '../../../../services/admin-display-language';
import { CustomColumnInputText } from '../../../../components/grid/custom-column';
import { useParams } from 'react-router-dom';
import { GetUpdatedVersionAsync } from '../../../../utils/game-document';
import DisplayLanguageIdShowNote from '../../../../constants/display-language';
import {
  setExpandedState,
  setGroupIds
} from '@progress/kendo-react-data-tools';

type selectedResource = {
  isOriginal?: boolean;
  resourceId?: string;
  type?: string;
  value?: string;
};

export default function DisplayLanguageEdit() {
  const initialDataState: State = {
    ...DefaultGridSettings.initialDataState,
    take: 2000,
    skip: 0
  };

  const { id } = useParams();

  const [libraries, setLibraries] = useState<DataResult>({
    data: [],
    total: 0
  });

  const [displayLanguage, setDisplayLanguage] =
    useState<AdminDisplayLanguageResponse>();

  const [displayLanguageDocument, setDisplayLanguageDocument] =
    useState<AdminDisplayLanguageDocumentResponse>();

  const [displayLanguageContent, setDisplayLanguageContent] =
    useState<DisplayLanguage>();

  const [dataState, setDataState] = useState<State>(initialDataState);

  const [collapsedState, setCollapsedState] = useState<string[]>([]);

  const updateResourceValue = (resourceId: string, newValue: string) => {
    let newDisplayLanguage = { ...displayLanguageContent };
    let resourceExists = false;
    let updatedResources = (displayLanguageContent?.resources ?? []).map(
      (resource) => {
        if (resource.id === resourceId) {
          resourceExists = true;
          return { ...resource, value: newValue };
        }
        return resource;
      }
    );

    if (!resourceExists) {
      const newResource: DisplayLanguageResource = {
        id: resourceId,
        name: '',
        description: '',
        value: newValue,
        type: 'text'
      };
      updatedResources.push(newResource);
    }

    newDisplayLanguage.resources = updatedResources;

    setDisplayLanguageContent(newDisplayLanguage);
  };

  const CustomCell = (props: GridCellProps) => {
    if (!props.dataItem.id) {
      return <></>;
    }
    return (
      <CustomColumnInputText
        columnClass={'w-50'}
        containerClass={'d-column w-full p-2'}
        showNote={
          DisplayLanguageIdShowNote.find((i) => i === props.dataItem.id) !==
          undefined
        }
        note={
          'When filling in this field, be sure to include the % sign to display the value correctly.'
        }
        text={
          displayLanguageContent?.resources?.find(
            (x) => x.id === props.dataItem.id
          )?.value ?? ''
        }
        onChange={(id, value) =>
          updateResourceValue(props.dataItem.id, value ?? '')
        }
      />
    );
  };

  const processWithGroups = (data: DisplayLanguageList[], dataState: State) => {
    dataState.group = [{ field: 'activityType' }];
    const newDataState = process(data, dataState);

    setGroupIds({ data: newDataState.data, group: dataState.group });

    newDataState.data = setExpandedState({
      data: newDataState.data,
      collapsedIds: collapsedState
    });

    newDataState.data = newDataState.data.sort((item) =>
      item.groupId === 'GameactivityType' ? -1 : 1
    );

    return newDataState;
  };

  const onSubmit = async ({ isPublished = false }) => {
    try {
      let newVersion = await GetUpdatedVersionAsync(
        'patch',
        displayLanguage!.version!.replace('v.', '')
      );

      newVersion = `v.${newVersion}`;

      const language = await AdminUpdateDisplayLanguageAsync(parseInt(id!), {
        languageCode: displayLanguage?.languageCode,
        description: displayLanguage?.description
      });

      const document = await AdminUpdateDisplayLanguageDocumentAsync(
        language.id!,
        displayLanguageDocument!.id!,
        {
          documentStatus: isPublished
            ? 'Published'
            : (displayLanguageDocument!.status as string),
          version: newVersion,
          fileName: `display-language-${language.id}-${newVersion}.json`
        }
      );

      await AdminAddDisplayLanguageContentAsync(
        language.id,
        document.id,
        displayLanguageContent
      );

      toastStore.show(
        'Display language',
        <div>Language updated successfully.</div>,
        'success'
      );

      fetchLibrary();
    } catch (error) {
      console.error('Failed to update language:', error);
      toastStore.show(
        'Display language',
        <div>Failed to update language.</div>,
        'error'
      );
    }
  };

  const fetchLibrary = useCallback(async () => {
    try {
      appStore.showLoading();
      const { group, ...apiState } = dataState;
      const result =
        await GetAllAdminDisplayLanguageDefaultValuesAsync(apiState);

      const displayLanguage = await GetAdminDisplayLanguageAsync(
        parseInt(id!),
        parseInt(id!)
      );

      const document = await GetAdminDisplayLanguageLatestDocumentAsync(
        parseInt(id!)
      );

      const content = await GetAdminDisplayLanguageLatestContentAsync(
        parseInt(id!)
      );

      const data = result.data
        .filter(
          (item) =>
            item.activityType !== '' && item.activityType !== 'Assessment'
        )
        .sort((item) =>
          item.activityType === 'Game' ? -1 : 1
        ) as DisplayLanguageList[];

      result.data = data;
      setLibraries(result);
      setDisplayLanguage(displayLanguage);
      setDisplayLanguageDocument(document);
      setDisplayLanguageContent(content);
    } catch (error) {
      console.error('Failed to fetch library:', error);
    } finally {
      appStore.hideLoading();
    }
  }, []);

  const onExpandChange = useCallback(
    (event: GridExpandChangeEvent) => {
      const item = event.dataItem;

      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );

  useEffect(() => {
    fetchLibrary();
  }, [id]);

  return (
    <PrivatePage
      breadcrumb={[
        { label: 'Dashboard', href: 'dashboard' },
        { label: 'Administration', href: '../administration' },

        {
          label: 'Languages management',
          href: 'administration/languages/manage'
        }
      ]}
      pageTitle={'Administration language'}>
      <>
        <Toolbar
          title={'Display language'}
          notificationTitle={'Display language'}
          showDefaultContent={false}>
          <Button
            themeColor={'primary'}
            className={'me-1'}
            onClick={() => onSubmit({ isPublished: false })}>
            Save
          </Button>
          <Button
            themeColor={'warning'}
            className={'me-1'}
            onClick={() => onSubmit({ isPublished: true })}>
            Publish
          </Button>
        </Toolbar>
        <div className={'pt-2'}>
          <GridToolBar
            searchPlaceholder={'Search text'}
            columnsToSearch={['value']}
            showCardMode={false}
            {...dataState}
            onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}></GridToolBar>

          <Grid
            editField={'isEditing'}
            className={'cg-grid3 grid-language-edit w-full'}
            data={processWithGroups(libraries?.data ?? [], dataState)}
            dataItemKey={'id'}
            {...dataState}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState);
            }}
            onExpandChange={onExpandChange}
            expandField="expanded">
            <GridToolbar>
              <Row
                className={
                  'd-flex justify-content-between w-100 align-items-center'
                }>
                <Col>
                  <span className={'fw-bold'}>Original</span>
                </Col>
                <Col>
                  <div className="d-flex justify-content-between gap-2">
                    <span className={'fw-bold'}>
                      {' '}
                      {displayLanguage?.languageName}
                    </span>
                  </div>
                </Col>
              </Row>
            </GridToolbar>
            <Column
              field={'value'}
              className="w-50"
              editable={false}
              headerClassName={'d-none'}
            />
            <Column
              className="w-50"
              editable={false}
              headerClassName={'d-none'}
              cell={(props) => CustomCell(props)}
            />

            <GridNoRecords>
              <NoRecords />
            </GridNoRecords>
          </Grid>
        </div>
      </>
    </PrivatePage>
  );
}
