import { useEffect, useMemo, useState } from 'react';
import {
  Editor,
  EditorChangeEvent,
  EditorMountEvent,
  EditorProps,
  EditorTools
} from '@progress/kendo-react-editor';
import { createView } from './create-markdown-view';
import { InsertMedia } from '../editor/insert-media-tools';
import { defaultMarkdownParser } from 'prosemirror-markdown';
import { Node as ProsemirrorNode } from 'prosemirror-model';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import * as svgIcons from '@progress/kendo-svg-icons';

const {
  Bold,
  Italic,
  Unlink,
  FormatBlock,
  Undo,
  Redo,
  OrderedList,
  UnorderedList
} = EditorTools;

const CustomLinkTool = ({ view }: any) => {
  const [showDialog, setShowDialog] = useState(false);
  const [webAddress, setWebAddress] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (showDialog && view) {
      const { from, to } = view.state.selection;
      const linkMark = view.state.doc.rangeHasMark(
        from,
        to,
        view.state.schema.marks.link
      );

      if (linkMark) {
        const selectedMarks = view.state.doc.slice(from, to);

        if (selectedMarks?.content?.content?.length) {
          const linkMarkFound = selectedMarks.content.content[0].marks.find(
            (mark: any) => mark.type === view.state.schema.marks.link
          );

          if (linkMarkFound) {
            const linkHref = linkMarkFound.attrs.href;
            const linkTitle = linkMarkFound.attrs.title || '';
            setWebAddress(linkHref);
            setTitle(linkTitle);
          }
        }
      }
    }
  }, [showDialog]);

  const insertLink = () => {
    if (view && webAddress) {
      const { schema } = view.state;
      const linkMark = schema.marks.link.create({
        href: webAddress,
        title
      });
      const { from, to } = view.state.selection;
      view.dispatch(view.state.tr.addMark(from, to, linkMark).scrollIntoView());
    }
    closeDialog();
  };

  const closeDialog = () => {
    setShowDialog(false);
    setWebAddress('');
    setTitle('');
  };

  return (
    <>
      <Button
        type={'button'}
        svgIcon={svgIcons.linkIcon}
        onClick={() => setShowDialog(true)}
      />

      {showDialog && (
        <Dialog
          width={600}
          title="Insert hyperlink"
          onClose={closeDialog}
          className="k-dialog-bootstrap">
          <div className="mb-3">
            <label className="form-label">Web address</label>
            <input
              type="text"
              value={webAddress}
              onChange={(e) => setWebAddress(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="form-control"
            />
          </div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              onClick={insertLink}>
              Insert
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={closeDialog}>
              Cancel
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

interface EditorInputProps extends EditorProps {
  handleMarkdownChange?: (event: EditorChangeEvent) => void;
}

export function MarkdownEditor({ ...props }: EditorInputProps) {
  const [value, setValue] = useState<ProsemirrorNode | undefined>(() => {
    const parsed = defaultMarkdownParser.parse(props.value as string);
    return parsed || undefined;
  });

  const onMount = (event: EditorMountEvent) => createView(event);

  const onChange = (e: EditorChangeEvent) => {
    setValue(e.value);
    if (props.handleMarkdownChange) props.handleMarkdownChange(e);
  };

  // inject iframe style after create custom link dialog
  // to force hyperlink result color black
  const injectStyles = (iframe: HTMLIFrameElement) => {
    if (!iframe) return;

    const doc = iframe.contentDocument || iframe.contentWindow?.document;
    if (doc) {
      const styleElement = doc.createElement('style');
      styleElement.textContent = `
        a {
          color: black !important;
        }
      `;
      doc.head.appendChild(styleElement);
    }
  };

  useEffect(() => {
    const iframe =
      document.querySelectorAll<HTMLIFrameElement>('.k-editor iframe');
    if (iframe.length) {
      for (let i = 0; i < iframe.length; i++) {
        injectStyles(iframe[i]);
      }
    }
  }, []);

  return (
    <Editor
      contentStyle={{ height: 500, width: '100%' }}
      onMount={onMount}
      tools={[
        [
          Bold,
          Italic,
          CustomLinkTool,
          Unlink,
          (pluginProps: any) =>
            InsertMedia({ ...pluginProps, resizeImage: false })
        ],
        [Undo, Redo],
        [OrderedList, UnorderedList],
        FormatBlock
      ]}
      onChange={onChange}
      value={value!}
    />
  );
}
